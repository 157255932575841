.ratios-page .ratio-form {
  margin: 0 auto 20px;
  width: 250px; }
  .ratios-page .ratio-form hr {
    margin: 30px 0; }

.ratios-page .ratio-inputs {
  display: flex;
  justify-content: space-between; }
  .ratios-page .ratio-inputs input {
    margin: 0;
    text-align: center;
    width: 100px; }
  .ratios-page .ratio-inputs input:read-only {
    color: #aaa;
    border: solid 1px #aaa; }
