@import '../../../../scss/variables.scss';

.no-tags-message {
	.empty-messaging {
		text-align: center;
	}

	.btn {
		display: block;
		margin: 0 auto;

		@media only screen and (min-width: 768px) {
			display: none;
		}
	}
}
