@import '../../../../scss/variables.scss';

/* Navbar
------------------------------------------------*/
.navbar {
	display: flex;
	font-size: 18px;
	height: $button-height;
	position: fixed;
	top: $header-height;
	z-index: 1;

	@media only screen and (max-width: 767px) {
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .25);
		left: 0;
		right: 0;
	}

	@media only screen and (min-width: 768px) {
		width: 50%;
		right: 0;
	}

	.navbar-item {
		flex: 1;
		height: $button-height;

		@media only screen and (max-width: 767px) {
			cursor: pointer;
		}

		&.active {
			@media only screen and (max-width: 767px) {
				background: #ccc;
			}
		}

		&.danger-background {
			color: #FFFFFF;
			background: #FF0000;
		}

		p {
			height: $button-height;
			line-height: $button-height;
			margin: 0;
			padding: 0;
			text-align: center;
		}
	}
}
