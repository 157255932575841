@import '../../../../scss/variables.scss';

.input-form {
	textarea {
		margin: 0;

		@media only screen and (max-width: 767px) {
			border: none;
		}

		@media only screen and (min-width: 768px) {
			min-height: 300px;
		}
	}

	@media only screen and (max-width: 767px) {
		&.single {
			textarea {
				height: 70px;
			}
		}

		&:not(.single) {
			textarea {
				min-height: 200px;
			}
		}
	}

	@media only screen and (max-width: 767px) {
		&:not(.dirty) {
			height: 100%;

			textarea {
				height: 100%;
			}
		}
	}

	input[type='submit'] {
		border-radius: 0;
	}

	.submit-container {
		@media only screen and (max-width: 767px) {
			margin: 0 10px;
		}
	}
}
