.output-content {
	@media only screen and (max-width: 767px) {
		padding: 10px 20px;
	}

	p {
		margin: 0 0 20px 0;
	}

	textarea {
		border: none;
		height: 0;
		padding: 0;
		margin: 0;
		outline: none;
		overflow: hidden;
	}

	.danger-text {
		color: #FF0000;
	}
}
