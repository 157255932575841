.bucket {
  border-bottom: solid 1px #000000; }
  .bucket .bucket-head {
    background: #ccc;
    cursor: pointer; }
    .bucket .bucket-head p {
      margin: 0;
      padding: 10px 20px; }
  .bucket ul {
    padding: 10px; }
  .bucket .add-all {
    display: block;
    margin: 0 auto 10px; }
