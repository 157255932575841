/* Colors
------------------------------------------------*/
$white: #FFFFFF;
$black: #000000;
$grey-lt: #cccccc;
$blue: #415082;
$blue-dk: #415082;
$red: #824150;
$red-dk: #683440;
$green: #96b48d;

$none: none;
$inherit: inherit;

/* Dimensions
------------------------------------------------*/
$button-height: 40px;
$header-height: 40px;
