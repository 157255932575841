/* Mobile Show & Hide
------------------------------------------------*/
.mobile-only {
	@media only screen and (min-width: 768px) {
		display: none;
	}
}

.mobile-hidden {
	@media only screen and (max-width: 767px) {
		display: none;
	}
}
