@import '../../scss/variables.scss';
@import '../../scss/classes.scss';
@import '../../scss/buttons.scss';
@import '../../scss/forms.scss';

/* Form Panel
------------------------------------------------*/
.form-panel {
	@media only screen and (min-width: 768px) {
		bottom: 0;
		left: 0;
		padding: 20px;
		position: fixed;
		top: $header-height;
		width: 50%;
	}
}

/* Alphabetical Panel
------------------------------------------------*/
.word-panel-section-content {
	 p {
		padding: 0 10px;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			height: $button-height;
			line-height: $button-height;
			overflow: hidden;
			padding-left: 15px;
		}
	}
}

.word-panel-section li:nth-child(5n) {
	border-bottom: solid 1px $black;
}

.mobile-full-page {
	bottom: 0;
	position: fixed;
	overflow-y: scroll;
	top: $button-height + $header-height;

	@media only screen and (max-width: 767px) {
		left: 0;
		right: 0;
	}
}

.input-panel,
.output-panel {
	@media only screen and (max-width: 767px) {
		bottom: 0;
		left: 0;
		overflow-y: scroll;
		position: fixed;
		right: 0;
		top: $button-height + $header-height;
	}
}

.ListSuggested {
	@media only screen and (min-width: 768px) {
		right: 0;
		width: 16.666666667%;
	}
}

.ListBuckets {
	@media only screen and (min-width: 768px) {
		right: 16.666666667%;
		width: 16.666666667%;
	}
}

.ListAlphabetical {
	@media only screen and (min-width: 768px) {
		right: 33.333333334%;
		width: 16.666666667%;
	}
}

.word-panel-section.ListAlphabetical li:hover {
	@media only screen and (min-width: 768px) {
		background: $grey-lt;
	}
}

.word-panel-section.ListSuggested {
	@media only screen and (min-width: 768px) {
		background: $grey-lt;
	}

	li:hover {
		@media only screen and (min-width: 768px) {
			background: #ddd;
		}
	}
}
