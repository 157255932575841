/* Colors
------------------------------------------------*/
/* Dimensions
------------------------------------------------*/
.nav {
  line-height: 40px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex; }
  .nav li {
    margin-left: 20px; }
    .nav li a {
      text-decoration: none; }
    .nav li a.active {
      text-decoration: underline; }
