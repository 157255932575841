.text-block-page form {
  margin: 0 auto 20px;
  display: block;
  width: 300px; }

.text-block-page input {
  border: solid 1px #000000;
  display: block;
  font-size: 20px;
  margin: 0 auto 20px;
  padding: 10px 20px; }
