@import './variables.scss';

input,
textarea {
	border: solid 1px $black;
	font-size: inherit;
	margin-bottom: 20px;
	outline: none;
	padding: 20px;
	width: 100%;
}
