@import './variables.scss';

button {
	cursor: pointer;
	margin-right: 10px;
	outline: none;
}

.btn {
	border-radius: 4px;
	color: $white;
	cursor: pointer;
	font-size: 20px;
	outline: none;
	padding: 15px 25px;
	text-transform: uppercase;
}

.btn-primary {
	background: $blue;

	&:hover {
		background: $blue-dk;
	}
}

.btn-secondary {
	color: $blue;

	&:hover {
		color: $blue-dk;
	}
}

.btn-danger {
	background: $red;

	&:hover {
		background: $red;
	}
}

.btn-delete {
	background: $none;
	border: none;
	color: $inherit;
	float: right;
	font-size: 20px;
	height: $button-height;
	margin: 0;
	padding: 0;
	width: $button-height;
}
