.text-block-page ul.color-picker {
  display: block;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 200px; }

.text-block-page ul.color-picker-row-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0; }

.text-block-page li.color-picker-item {
  cursor: pointer;
  height: 40px;
  position: relative;
  width: 40px; }

.text-block-page svg {
  fill: #FFFFFF;
  display: block;
  height: 16px;
  margin-left: 12px;
  margin-top: 12px;
  width: 16px; }

.text-block-page .color-toggle {
  cursor: pointer;
  text-align: center;
  margin: 0 0 20px 0;
  text-decoration: underline; }
