/* Colors
------------------------------------------------*/
/* Dimensions
------------------------------------------------*/
.header {
  height: 40px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }
