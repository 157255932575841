/* General
------------------------------------------------*/
body {
  font-size: 20px;
  margin: 0; }

* {
  box-sizing: border-box; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.margin {
  margin: 20px; }

.margin-bottom {
  margin-bottom: 20px; }

.margin-left {
  margin-left: 20px; }

.margin-right {
  margin-right: 20px; }

.margin-top {
  margin-top: 20px; }

.padding {
  padding: 20px; }

.padding-bottom {
  padding-bottom: 20px; }

.padding-left {
  padding-left: 20px; }

.padding-right {
  padding-right: 20px; }

.padding-top {
  padding-top: 20px; }
